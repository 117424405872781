import React, { useEffect, useState } from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
// import './css/Resume.css'

function Resume() {
  const [dimensions, setDimensions] = useState({ width: 640, height: 829 })

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight
      const newHeight = windowHeight * 0.8
      const aspectRatio = 640 / 829
      const newWidth = newHeight * aspectRatio

      setDimensions({ width: newWidth, height: newHeight })
    }

    handleResize() // Call once to set initial dimensions
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="Resume">
      <iframe
        src="https://drive.google.com/file/d/1X9Wurf4B7Cf8aOr5fgde2_s4AhS6LOm2/preview"
        style={{
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
        }}
        allow="autoplay"></iframe>
    </div>
  )
}

export default Resume
