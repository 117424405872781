import React from 'react'
import './css/Details.css'
import Resume from './content/Resume'
import About from './content/About'
import Publications from './content/Publications'
import SkillSet from './content/Skills'

function Details({ content }) {
  let contentComponent
  if (content === 'Resume') contentComponent = <Resume />
  else if (content === 'About') contentComponent = <About />
  else if (content === 'Publications') contentComponent = <Publications />
  else if (content === 'Skills') contentComponent = <SkillSet />
  return <div className="DetailsContent">{contentComponent}</div>
}

export default Details
