import React from 'react'
import { motion } from 'framer-motion'
import '../css/Publications.scss'

const publications = [
  {
    title:
      'The Impact of Group Discussion and Formation on Student Performance: An Experience Report in a Large CS1 Course',
    authors: [
      'Tong Wu',
      'Xiaohang Tang',
      'Sam Wong',
      'Xi Chen',
      'Cliff Shaffer',
      'Yan Chen',
    ],
    publication: 'SIGCSE TS 2025 Papers',
    date: '2024',
    link: 'https://sigcse2025.sigcse.org/details/sigcse-ts-2025-Papers/192/The-Impact-of-Group-Discussion-and-Formation-on-Student-Performance-An-Experience-Re',
    img: 'https://chensivan.github.io/img/vizpi_sigcse25.jpg',
  },
  {
    title:
      'VizGroup: An AI-Assisted Event-Driven System for Real-Time Collaborative Programming Learning Analytics.',
    authors: [
      'Xiaohang Tang',
      'Sam Wong',
      'Kevin Pu',
      'Xi Chen',
      'Yalong Yang',
      'Yan Chen',
    ],
    publication: 'UIST',
    date: '2024',
    link: 'https://arxiv.org/abs/2404.08743',
    img: 'https://chensivan.github.io/img/vizgroup.png',
  },
  {
    title:
      'VizPI: A Real-Time Visualization Tool for Enhancing Peer Instruction in Large-Scale Programming Lectures.',
    authors: ['Xiaohang Tang', 'Xi Chen', 'Sam Wong', 'Yan Chen'],
    publication: 'UIST Poster',
    date: '2023',
    link: 'https://chensivan.github.io/papers/vizpi.pdf',
    img: 'https://chensivan.github.io/img/vizpi.png',
  },
]

function Publications() {
  return (
    <div className="publications">
      {publications.map((pub, index) => (
        <motion.div
          className="publication"
          key={index}
          initial={{ opacity: 0, y: 50, overflow: 'hidden' }}
          animate={{ opacity: 1, y: 0, overflow: 'hidden' }}
          transition={{ duration: 0.05, delay: index * 0.2 }}
          whileHover={{ scale: 1.05 }} // 添加悬停效果
        >
          <img src={pub.img} alt={pub.title} className="publication-image" />
          <div className="publication-content">
            <h3 className="publication-title">
              <a href={pub.link} target="_blank" rel="noopener noreferrer">
                {pub.title}
              </a>
            </h3>
            <p className="publication-authors">{pub.authors.join(', ')}</p>
            <p className="publication-info">
              {pub.publication} - {pub.date}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  )
}

export default Publications
