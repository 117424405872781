import React from 'react'
import '../css/SkillSet.scss'

const SkillSet = () => {
  return (
    <div className="skillset">
      <h2>Languages & Skills 💻</h2>
      <table>
        <thead>
          <tr>
            <th>Domain</th>
            <th>Technologies</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Languages</strong>
            </td>
            <td>
              <a href="https://www.java.com">
                <img
                  src="https://img.shields.io/badge/-Java-007396?style=flat-square&logo=java&logoColor=white"
                  alt="Java"
                />
              </a>
              <a href="https://www.javascript.com">
                <img
                  src="https://img.shields.io/badge/-JavaScript-F7DF1E?style=flat-square&logo=javascript&logoColor=black"
                  alt="JavaScript"
                />
              </a>
              <a href="https://www.python.org">
                <img
                  src="https://img.shields.io/badge/-Python-3776AB?style=flat-square&logo=python&logoColor=white"
                  alt="Python"
                />
              </a>
              <a href="https://en.wikipedia.org/wiki/C_(programming_language)">
                <img
                  src="https://img.shields.io/badge/-C-A8B9CC?style=flat-square&logo=c&logoColor=white"
                  alt="C"
                />
              </a>
              <a href="https://isocpp.org">
                <img
                  src="https://img.shields.io/badge/-C++-00599C?style=flat-square&logo=cplusplus&logoColor=white"
                  alt="C++"
                />
              </a>
              <a href="https://en.wikipedia.org/wiki/SQL">
                <img
                  src="https://img.shields.io/badge/-SQL-4479A1?style=flat-square&logo=sql&logoColor=white"
                  alt="SQL"
                />
              </a>
              <a href="https://www.r-project.org">
                <img
                  src="https://img.shields.io/badge/-R-276DC3?style=flat-square&logo=r&logoColor=white"
                  alt="R"
                />
              </a>
              <a href="https://ocaml.org">
                <img
                  src="https://img.shields.io/badge/-OCaml-EC6813?style=flat-square&logo=ocaml&logoColor=white"
                  alt="OCaml"
                />
              </a>
              <a href="https://www.swi-prolog.org">
                <img
                  src="https://img.shields.io/badge/-Prolog-7427F5?style=flat-square&logo=prolog&logoColor=white"
                  alt="Prolog"
                />
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Frontend</strong>
            </td>
            <td>
              <a href="https://reactjs.org">
                <img
                  src="https://img.shields.io/badge/-React-61DAFB?style=flat-square&logo=react&logoColor=black"
                  alt="React"
                />
              </a>
              <a href="https://vuejs.org">
                <img
                  src="https://img.shields.io/badge/-Vue.js-4FC08D?style=flat-square&logo=vue.js&logoColor=white"
                  alt="Vue.js"
                />
              </a>
              <a href="https://www.javascript.com">
                <img
                  src="https://img.shields.io/badge/-JavaScript-F7DF1E?style=flat-square&logo=javascript&logoColor=black"
                  alt="JavaScript"
                />
              </a>
              <a href="https://www.w3.org/TR/html5/">
                <img
                  src="https://img.shields.io/badge/-HTML5-E34F26?style=flat-square&logo=html5&logoColor=white"
                  alt="HTML5"
                />
              </a>
              <a href="https://www.w3.org/Style/CSS/Overview.en.html">
                <img
                  src="https://img.shields.io/badge/-CSS3-1572B6?style=flat-square&logo=css3&logoColor=white"
                  alt="CSS3"
                />
              </a>
              <a href="https://sass-lang.com">
                <img
                  src="https://img.shields.io/badge/-SCSS-CC6699?style=flat-square&logo=sass&logoColor=white"
                  alt="SCSS"
                />
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Backend</strong>
            </td>
            <td>
              <a href="https://nodejs.org">
                <img
                  src="https://img.shields.io/badge/-Node.js-339933?style=flat-square&logo=node.js&logoColor=white"
                  alt="Node.js"
                />
              </a>
              <a href="https://expressjs.com">
                <img
                  src="https://img.shields.io/badge/-Express.js-000000?style=flat-square&logo=express&logoColor=white"
                  alt="Express.js"
                />
              </a>
              <a href="https://spring.io/">
                <img
                  src="https://img.shields.io/badge/-Spring-6DB33F?style=flat-square&logo=spring&logoColor=white"
                  alt="Spring"
                />
              </a>
              <a href="https://spring.io/projects/spring-boot">
                <img
                  src="https://img.shields.io/badge/-Spring_Boot-6DB33F?style=flat-square&logo=spring-boot&logoColor=white"
                  alt="Spring Boot"
                />
              </a>
              <a href="https://sequelize.org/">
                <img
                  src="https://img.shields.io/badge/-Sequelize-52B0E7?style=flat-square&logo=sequelize&logoColor=white"
                  alt="Sequelize"
                />
              </a>
              <a href="https://mybatis.org/mybatis-3/">
                <img
                  src="https://img.shields.io/badge/-MyBatis_Plus-007396?style=flat-square&logo=mybatis&logoColor=white"
                  alt="MyBatis Plus"
                />
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Cloud</strong>
            </td>
            <td>
              <a href="https://aws.amazon.com">
                <img
                  src="https://img.shields.io/badge/-AWS-232F3E?style=flat-square&logo=amazon-aws&logoColor=white"
                  alt="AWS"
                />
              </a>
              <a href="https://cloud.google.com">
                <img
                  src="https://img.shields.io/badge/-Google_Cloud-4285F4?style=flat-square&logo=google-cloud&logoColor=white"
                  alt="Google Cloud"
                />
              </a>
              <a href="https://azure.microsoft.com">
                <img
                  src="https://img.shields.io/badge/-Azure-0089D6?style=flat-square&logo=microsoft-azure&logoColor=white"
                  alt="Azure"
                />
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Database</strong>
            </td>
            <td>
              <a href="https://www.postgresql.org">
                <img
                  src="https://img.shields.io/badge/-PostgreSQL-336791?style=flat-square&logo=postgresql&logoColor=white"
                  alt="PostgreSQL"
                />
              </a>
              <a href="https://www.mysql.com">
                <img
                  src="https://img.shields.io/badge/-MySQL-4479A1?style=flat-square&logo=mysql&logoColor=white"
                  alt="MySQL"
                />
              </a>
              <a href="https://firebase.google.com">
                <img
                  src="https://img.shields.io/badge/-Firebase-FFCA28?style=flat-square&logo=firebase&logoColor=black"
                  alt="Firebase"
                />
              </a>
              <a href="https://www.elastic.co/elasticsearch/">
                <img
                  src="https://img.shields.io/badge/-Elasticsearch-005571?style=flat-square&logo=elasticsearch&logoColor=white"
                  alt="Elasticsearch"
                />
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Tools</strong>
            </td>
            <td>
              <a href="https://git-scm.com">
                <img
                  src="https://img.shields.io/badge/-Git-F05032?style=flat-square&logo=git&logoColor=white"
                  alt="Git"
                />
              </a>
              <a href="https://www.docker.com">
                <img
                  src="https://img.shields.io/badge/-Docker-2496ED?style=flat-square&logo=docker&logoColor=white"
                  alt="Docker"
                />
              </a>
              <a href="https://nginx.org">
                <img
                  src="https://img.shields.io/badge/-Nginx-009639?style=flat-square&logo=nginx&logoColor=white"
                  alt="Nginx"
                />
              </a>
              <a href="https://www.netlify.com">
                <img
                  src="https://img.shields.io/badge/-Netlify-00C7B7?style=flat-square&logo=netlify&logoColor=white"
                  alt="Netlify"
                />
              </a>
              <a href="https://www.postman.com">
                <img
                  src="https://img.shields.io/badge/-Postman-FF6C37?style=flat-square&logo=postman&logoColor=white"
                  alt="Postman"
                />
              </a>
              <a href="https://www.linux.org">
                <img
                  src="https://img.shields.io/badge/-Linux-FCC624?style=flat-square&logo=linux&logoColor=black"
                  alt="Linux"
                />
              </a>
              <a href="https://langchain.com">
                <img
                  src="https://img.shields.io/badge/-Langchain-007396?style=flat-square&logo=langchain&logoColor=white"
                  alt="Langchain"
                />
              </a>
              <a href="https://www.twilio.com">
                <img
                  src="https://img.shields.io/badge/-Twilio-F22F46?style=flat-square&logo=twilio&logoColor=white"
                  alt="Twilio"
                />
              </a>
              <a href="https://github.com/ultralytics/yolov5">
                <img
                  src="https://img.shields.io/badge/-YOLOv5-000000?style=flat-square&logo=github&logoColor=white"
                  alt="YOLOv5"
                />
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <strong>IDE / Editors</strong>
            </td>
            <td>
              <a href="https://www.jetbrains.com/idea/">
                <img
                  src="https://img.shields.io/badge/-IntelliJ_IDEA-000000?style=flat-square&logo=intellij-idea&logoColor=white"
                  alt="IntelliJ IDEA"
                />
              </a>
              <a href="https://www.jetbrains.com/pycharm/">
                <img
                  src="https://img.shields.io/badge/-PyCharm-000000?style=flat-square&logo=pycharm&logoColor=white"
                  alt="PyCharm"
                />
              </a>
              <a href="https://code.visualstudio.com">
                <img
                  src="https://img.shields.io/badge/-VS_Code-007ACC?style=flat-square&logo=visual-studio-code&logoColor=white"
                  alt="VS Code"
                />
              </a>
              <a href="https://developer.android.com/studio">
                <img
                  src="https://img.shields.io/badge/-Android_Studio-3DDC84?style=flat-square&logo=android-studio&logoColor=white"
                  alt="Android Studio"
                />
              </a>
              <a href="https://www.eclipse.org/ide/">
                <img
                  src="https://img.shields.io/badge/-Eclipse_IDE-2C2255?style=flat-square&logo=eclipse-ide&logoColor=white"
                  alt="Eclipse IDE"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SkillSet
